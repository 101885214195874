.describe-image-container {
  padding: 0px 0px 15px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-basis: auto;
  font-family: var(--roboto);
  .describe-image-box {
    .describe-image-heading {
      font-weight: 700;
      margin-bottom: 50px;
    }
    .describe-image-body {
      display: flex;
      justify-content: center;
      .describe-image-image {
        flex-basis: 40%;
        margin-right: 10%;
        height: 300px;
        margin-top: 25px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 20px;
        }
      }
      .audio-box {
        align-self: center;
        margin: none;
      }
    }
  }
  .describe-image-footer {
    display: flex;
    justify-content: flex-end;
    .alternate-button {
      margin-right: 20px;
    }
  }
}
