.listening-fib-container {
    padding: 0px 0px 15px 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-basis: auto;
    font-family: var(--roboto);
    .listening-fib-box {
      .practice-header{
          
      }
      .audio-box {
        .status-heading {
        }
        .status-text {
        }
      }
      .fib-texts {
        line-height: 1.5;
        .selectable-text{
          cursor: pointer;
          border-radius: 10px;
          // padding: 5px;

        }
        input {
          padding: 4px;
          width: 140px;
          height: 27px;
          font-size: 14px;
        }
      }
    }
  }
  