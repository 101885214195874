.stats-box {
  flex-basis: 30%;
  flex-grow: 1;
  background-color: #fff;
  margin-right: 3%;
  margin-bottom: 17px;
  padding: 15px;
  border-radius: 20px;
  box-shadow: 0px 0px 2px 0px #ccc;
  &:not(:last-child) {
  }
  &:nth-child(3) {
    margin-right: 0;
  }
  &:nth-child(6) {
    margin-right: 0;
  }
  .stats-name {
    font-size: 14px;
    margin-bottom: 5px;
  }
  .stats-values {
    display: flex;
    align-items: center;
    .stats-number {
      font-size: 22px;
      margin-right: 16px;
      font-weight: 600;
    }
    .stats-percent {
      background-color: #1366b061;
      border-radius: 20px;
      padding: 3px 10px;
      font-size: 12px;
      display: inline-flex;
      align-items: center;
      color: green;

      .trend-icon {
        margin-left: 5px;
        color: green;
      }
    }
  }
}
