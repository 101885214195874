.analytics-box-container {
  display: flex;
  height: 80px;
  width: 100%;
  background-color: #fff;
  margin-bottom: 30px;
  box-shadow: 0 3px 3px rgba(56, 65, 74, 0.1);
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(3px);
  padding: 10px 10px;
  color: #fff;
  border-radius: 20px;
  border: 1px solid var(--main);
  .analytics-box-icon {
    width: 90px;
    max-width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    font-size: 32px;
  }
  .analytics-box-text {
    font-size: 12px;
    font-weight: 600;
    font-family: "Roboto", sans-serif;
    // padding: 10px 0;
    .analytics-box-title {
      margin-bottom: 0px;
    }
    .analytics-box-data {
      text-align: right;
    }
  }
}
