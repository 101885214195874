.landing-trends-container {
  background-color: #f3f7fb;
  padding: 70px 0;
  .landing-trends-header {
    text-align: center;
  }
  .landing-trends-sub-header {
    text-align: center;
  }
  .landing-trends-body {
    display: flex;
    margin-top: 50px;

    .landing-trend-box {
      overflow: hidden;
      border-radius: 20px;
      flex-basis: 30%;
      box-shadow: 3px 5px 10px 1px rgba(0, 0, 0, 0.25);
      &:not(:last-child) {
        margin-right: 4%;
      }
      .landing-trend-image {
        height: 197px;
        width: 100%;
        overflow: hidden;
        &:hover{
          img{
            opacity: 0.8;
            transform: scale(1.1);
          }
        }
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          transition: all 500ms ease;
        }
      }
      .landing-trend-info {
        text-align: center;
        padding: 15px 20px;
        background-color: #fff;
        .trend-box-header {
          font-size: 20px;
          font-weight: 600;
          cursor: pointer;
          transition: all 200ms ease;
          &:hover{
            color: var(--brand-color-1);
          }
        }
        .trend-box-detail {
          margin-top: 12px;
          margin-bottom: 12px;
          font-size: 14px;
        }
        .trend-box-link {
          position: relative;
          color: var(--brand-color-1);
          display: inline-block;
          cursor: pointer;
          &::after {
            content: "";
            bottom: 0;
            height: 1px;
            left: 0;
            background-color: var(--brand-color-1);
            border-radius: 10px;
            position: absolute;
            width: 100%;
            transform: scaleX(0);
            transform-origin: left;
            transition: all 500ms ease;
          }
          &:hover {
            &::after {
              transform: scaleX(1);
            }
          }
        }
      }
    }
  }
}
