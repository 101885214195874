.dashboard-page-container {
  padding: 0px 0px 15px 0px;
  font-family: var(--roboto);
  width: 100%;
  .dashboard-page-inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    .dashboard-top {
      .dashboard-section-name {
        margin-bottom: 10px;
        font-weight: 500;
      }
    }
    .dashboard-main {
      flex-basis: 55%;
      flex-grow: 1;
      margin-right: 5%;
      .main-section-name {
        position: relative;
        display: flex;
        justify-content: space-between;
        .section-name {
          display: flex;
          align-items: center;
        }
        .section-name-right {
          flex-grow: 1;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          padding: 0 0 0 10px;
          .dash-line{
            height: 1px;
            background-color: #ccc;
            flex-grow: 1;
            margin-right: 10px;
          }
          .dash-date {
            font-size: 12px;
            font-weight: 300;
            background-color: #f0f0f0;
            padding: 8px 15px;
            border-radius: 30px;
          }
        }
      }
      .dashboard-top {
        .dashboard-section-name {
        }
        .stats-box-container {
          display: flex;
          flex-wrap: wrap;
        }
      }
    }
    .dashboard-right {
      flex-basis: 25%;
      flex-shrink: 1;
      .dashboard-quick-links-container {
        background-color: #f0f0f0;
        border-radius: 20px;
        margin-bottom: 30px;
      }
      .dashboard-members-container{
        background-color: #f0f0f0;
        border-radius: 20px;
      }
    }
  }
}
.line-graph-container{
  height: 280px;
}