.landing-offer-container {
  padding: 80px 0;
  background-image: url("https://template.coomb.co/erudite/wp-content/uploads/sites/10/2020/12/Flexi4A.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #D9FAFF;
  background-attachment: fixed;
  .landing-offer-header {
    text-align: center;
  }
  .landing-offer-sub-header {
    text-align: center;
  }

  .landing-offer-body {
    display: flex;
    margin-top: 100px;
    .offer-one {
      flex-basis: 22%;
      box-shadow: 1px 1px 9px -1px #ccc;
    }
    .offer-header {
      box-shadow: none;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .offer-title {
        font-size: 32px;
      }
      .offer-detail {
      }
    }
    .offer-data-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 15px 15px;
      &:not(:last-child) {
        margin-right: 3%;
      }
      .offer-data-icon {
        font-size: 22px;
        svg {
          width: 30px;
          height: 30px;
        }
        margin-bottom: 20px;
      }
      .offer-data-title {
        font-size: 16px;
        text-align: center;
        margin-bottom: 15px;
      }
      .offer-data-detail {
        text-align: center;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
      }
    }
  }
}
