.quick-links {
  display: flex;
  padding: 12px 12px;
  cursor: pointer;
  align-items: center;
  &:not(:last-child) {
    border-bottom: 1px solid #cccccc75;
  }
  .quick-link-icon {
    height: 30px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    // background-color: var(--brand-color-1);
    color: var(--brand-color-1);
    margin-right: 10px;
  }
  .quick-link-name {
    font-weight: 500;
    font-size: 14px;
  }
}
