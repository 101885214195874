.rules-page {
    .rules-page-inner {
        height: 100vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .practice-header {
            font-size: 60px;
            line-height: 66px;
            letter-spacing: 0.5px;
            margin-top: 0px;
            margin-bottom: 15px;
            color: var(--brand-color-1);
        }
        .rules-list{
            display: flex;
            flex-direction: column;
            row-gap: 6px;
            margin-bottom: 40px;
            .rules-item{
                display: flex;
                align-items: center;
                span{
                    color: var(--brand-color-1);
                margin-right: 8px;

                }

            }

        }
        .rules-buttons {
            display: flex;
            justify-content: flex-end;
            column-gap: 30px;
        }
    }
}
