.testing-page{
    .testing-page-inner{
        height: 100vh;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .testing-buttons{
            display: flex;
            column-gap: 30px;
        }
        .practice-header {
            font-size: 30px;
            line-height: 66px;
            letter-spacing: 0.5px;
            margin-top: 0px;
            margin-bottom: 15px;
            color: var(--brand-color-1);
        }
    }
}