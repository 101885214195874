.wfd-container {
  padding: 0px 0px 15px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-basis: auto;
  font-family: var(--roboto);
  width: 100%;
  .wfd-box {
    .practice-header {
    }
    .wfd-audio-box {
      .summarize-spoken-status {
      }
      .summarize-status-text {
      }
    }
    .wfd-text-box {
      margin-bottom: 20px;
      textarea {
        // height: 100px;
        // width: 100%;
        // border: 1px solid var(--brand-color-1);
        // border-radius: 20px;
        // padding: 10px 20px;
      }
    }
  }
  .wfd-footer {
    display: flex;
    justify-content: space-between;
  }
}
