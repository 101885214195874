.landing-provides-container {
  padding: 80px 0;

  .landing-provides-header {
    text-align: center;
  }
  .landing-provides-sub-header {
    text-align: center;
  }
}

.landing-provides {
  display: flex;
  margin-top: 50px;
  flex-wrap: wrap;
  .st-box {
    flex-basis: 22%;
    box-shadow: 1px 1px 9px -1px #ccc;
    padding: 20px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    margin-bottom: 20px;
    height: 135px;
    cursor: pointer;
    &:hover{
      box-shadow: 1px 1px 12px 3px #ccc;

    }
    &:not(:last-child) {
      margin-right: 3%;
    }
    &:nth-child(4){
      margin-right: 0;
    }
    .st-icon {
      margin-bottom: 8px;
      color: var(--brand-color-1);
      svg{
        width: 40px;
        font-size: 40px;
      }
      i{
        color: var(--brand-color-1);
      }
    }
    .st-info {
      display: flex;
      align-items: center;
      .st-title {
        font-size: 15px;
        margin-right: 15px;
      }
      .st-plus {
        svg {
          font-size: 18px;
          color: var(--brand-color-1);
          width: 13px;
        }
      }
    }
  }
}
