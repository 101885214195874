.summarize-text-container {
  padding: 0px 0px 15px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-basis: auto;
  font-family: var(--roboto);
  .summarize-text-top {
  }
  .summarize-text-question-box {
    .practice-header {
    }
    .practice-desc {
      margin-bottom: 20px;
    }
  }
  .summarize-text-answer-box {
    margin-bottom: 20px;
    textarea {
      height: 250px;
    }
  }
  .summarize-text-footer {
    display: flex;
    justify-content: flex-end;
    .alternate-button {
      margin-right: 20px;
    }
  }
}
