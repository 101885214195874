.hcm-container {
  padding: 0px 0px 15px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-basis: auto;
  font-family: var(--roboto);
  .hcm-box {
    display: flex;
    .hcm-left {
      flex-basis: 40%;
      margin-right: 5%;
      flex-grow: 1;
      .audio-box {
        .status-heading {
        }
        .status-text {
        }
      }
    }
    .hcm-right {
      flex-basis: 40%;
      flex-grow: 1;
      .practice-header{
      
      }
      // .hcm-question {
      //   margin-bottom: 30px;
      // }
      .hcm-selection-container {
        .hdl-item {
          display: flex;
          align-items: flex-start;
          margin-bottom: 30px;
          cursor: pointer;
          .hdl-circle {
            height: 30px;
            width: 30px;
            border-radius: 50%;
            margin-right: 10px;
          }
          .hdl-para {
          }
        }
      
      }
    }
  }
}
