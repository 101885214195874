.landing-video-container {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  position: relative;
  z-index: 1;
  color: #fff;
  text-align: center;
  background: rgba(19, 102, 176, 0.05);

  padding: 70px 0;

  .landing-video-header {
    text-align: center;
  }
  .landing-video-sub-header {
    text-align: center;
  }
  .video-body {
    display: flex;
    margin-top: 50px;
    .video-box {
      flex-basis: 30%;
      border-radius: 20px;
      overflow: hidden;
      position: relative;
      border: 1px solid var(--brand-color-1);
      &:not(:last-child) {
        margin-right: 4%;
      }
      video {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
      #video-controls{
        position: absolute;
        bottom: 0;
        left: 0;
        height: 100%;
        width: 100%;
        #play-pause{
          background-color: var(--brand-color-1);
          border: 1px solid var(--brand-color-1);
          height: 35px;
          width: 35px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          color: #fff;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
}
