.mock-test-box {
  flex-basis: 30%;
  margin-right: 3%;
  margin-bottom: 30px;
  background-color: #f0f0f0;
  padding: 20px 0px;
  border-radius: 20px;
  font-family: var(--roboto);
  transition: all 500ms ease;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 0px 3px 1px #ccc;
  }
  .mock-text-top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    padding: 0 10px;
    .mock-test-header {
      font-weight: 700;
    }
  }
  .mock-test-middle {
    margin-bottom: 30px;
    display: flex;
    padding: 0 10px;

    .test-middle-box {
      display: flex;
      flex-direction: column;
      &:first-child {
        margin-right: 30px;
      }
      .test-middle-title {
      }
      .test-middle-number {
        font-size: 14px;
        color: grey;
      }
    }
    .test-duration {
    }
  }

  .mock-test-score {
    display: flex;
    margin-bottom: 30px;
    justify-content: space-between;
    padding: 10px 10px;
    border: 1px solid transparent;
    border-top-color: transparent;
    border-bottom-color: transparent;
    border-top-color: #fff;
    border-bottom-color: #fff;
    color: #fff;
    .score-title {
    }
    .score-number {
    }
  }
  .mock-test-bottom {
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
    margin-bottom: 30px;
    .mock-test-bottom-box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .bottom-icon {
        color: var(--brand-color-1);
      }
      .bottom-text {
        font-size: 14px;
        color: grey;
      }
    }
    .mock-test-result {
    }
  }
  .mock-test-status {
    font-size: 14px;
    background-color: var(--brand-color-1);
    border-radius: 20px;
    color: #fff;
    padding: 4px 10px;
    margin: 0 10px;
    text-align: center;
    height: 30px;
    display: flex;
    padding: 4px 10px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}
