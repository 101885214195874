.footer-container {
  min-height: 40vh;
  background-color: #fff;
  .upper-footer {
    padding: 80px 0;
    background-image: url("https://template.coomb.co/erudite/wp-content/uploads/sites/10/2020/12/erudite-photo-e-1.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    position: relative;
    z-index: 1;
    color: #fff;
    text-align: center;
    padding: 80px 0px;
    &::after {
      content: "";
      //   background-color: #11538c;
      background-color: rgba(0, 0, 0, 0.6);
      opacity: 0.9;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: -2;
    }
    .upper-footer-info {
      align-items: center;
      .upper-footer-heading {
        text-align: left;
        font-size: 28px;
      }
      .upper-footer-detail {
        text-align: left;
      }
    }
    .upper-footer-form {
      .upper-footer-input-form {
        display: flex;
        border-radius: 20px;
        overflow: hidden;
        input {
          width: 80%;
          padding: 5px 20px;
          &:active {
          }
        }
        .upper-footer-input-button {
          width: 20%;
          color: #fff;
          background-color: var(--brand-color-1);
          padding: 5px 3px;
        }
      }
    }
  }

  .lower-footer {
    background-color: var(--brand-color-1);
    padding: 40px 0;
    color: #fff !important;
    text-decoration: none;
    .footer-about {
      .footer-image {
        position: relative;
        width: 163px;
        height: 60px;
        overflow: hidden;
        img {
          position: absolute;
          left: -70px;
          width: 300px;
          top: -118px;
        }
      }
      .footer-about-text {
      }
      .footer-links {
        display: flex;
        margin-top: 20px;
        a {
          color: #fff;
          display: flex;
          &:not(:last-child) {
            margin-right: 15px;
          }
        }
      }
    }
    .footer-header {
      font-size: 22px;
    }
    .footer-body {
      .footer-item {
        color: #fff !important;
        text-decoration: none;
        display: flex;
        padding: 5px 0;
        font-family: var(--roboto);
        font-weight: 300;
        .footer-contact-icon{
          margin-right: 8px;
        }
      }
    }
  }
  .footer-bottom {
    background-color: #063351;
    height: 40px;
    color: #fff;
    text-align: center;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
