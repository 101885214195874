.fill-in-the-blanks-container {
  padding: 0px 0px 15px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-basis: auto;
  font-family: var(--roboto);
  .fill-in-question-box {
    font-family: var(--roboto);
    width: 100%;
    .practice-header {
    }
    .practice-desc {
      line-height: 1.9;
      select {
        margin: 0 8px;
      }
    }
  }
  .fill-in-footer {
    display: flex;
    justify-content: flex-end;
    .alternate-button {
      margin-right: 20px;
    }
  }
}
