.read-aloud-container {
  padding: 0px 0px 15px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-basis: auto;
  .test-page-header{
    font-size: 30px;
    line-height: 66px;
    letter-spacing: 0.5px;
    margin-top: 0px;
    margin-bottom: 15px;
    color: var(--brand-color-1);
  }
  .read-aloud-header {
    .read-aloud-header-left {
    }
    .read-aloud-header-right {
    }
  }
  .read-aloud-body {
    .read-aloud-box {
      .read-aloud-heading {
        margin-bottom: 30px;
        font-family: var(--roboto);
        font-weight: 500;
      }
      .audio-box {
        .status-time {
        }
        .completed-mark {
        }
        .status-heading {
          .status-text {
          }
        }
        .audio-react-recorder {
          height: 100px;
          width: 100%;
        }
        .audio-react-recorder__canvas {
          // height: 100%;
          // width: 100%;
        }
      }
      .read-aloud-statement {
        font-family: var(--roboto);
      }
    }
  }

}
