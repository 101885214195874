.nav-container {
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .nav-item {
    text-decoration: none;
    color: #000;
    cursor: pointer;

    &:not(:last-child) {
      margin-right: 25px;
    }
    &:hover {
      color: var(--brand-color-1);
    }
  }
}
