.repeat-sentence-container {
  padding: 0px 0px 15px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-basis: auto;
  font-family: var(--roboto);
  .repeat-sentence-box {
    .section-header {
    }
    .audio-box {
      margin: 0;
      margin-top: 40px;
      margin-left: auto;
      margin-right: auto;
      align-self: center;
      .repeat-sound-status {
        margin-bottom: 4px;
      }
      .repeat-status-text {
      }
      .repeat-progress-box {
        margin-top: 30px;
        .status-progress {
          .status-progress-inner {
          }
        }
      }
    }
  }
  .repeat-sentence-footer {
    display: flex;
    justify-content: flex-end;
    .alternate-button {
      margin-right: 20px;
    }
  }
}
