.test-container {
    padding: 0px 0px 15px 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-basis: 100%;
    .test-page-header{
      font-size: 30px;
      line-height: 66px;
      letter-spacing: 0.5px;
      margin-top: 0px;
      margin-bottom: 15px;
      color: var(--brand-color-1);
    }
    .test-header {
      .test-header-left {
      }
      .test-header-right {
      }
    }
    
    .test-footer {
      display: flex;
      justify-content: flex-end;
      .alternate-button {
        margin-right: 20px;
      }
    }
  }
  