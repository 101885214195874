#bars {
  height: 50px;
//   left: 50%;
  position: relative;
  width: 77px;
}
.bars-outer{

}
.bar {
  background: var(--brand-color-1);
  bottom: 1px;
  height: 100%;
  position: absolute;
  width: 4px;
  animation: sound 0ms -800ms linear infinite alternate;
}

@keyframes sound {
  0% {
    opacity: 0.35;
    height: 15px;
  }
  100% {
    opacity: 1;
    height: 100%;
  }
}

.bar:nth-child(1) {
  left: 1px;
  animation-duration: 474ms;
}
.bar:nth-child(2) {
  left: 5px;
  animation-duration: 433ms;
}
.bar:nth-child(3) {
  left: 9px;
  animation-duration: 407ms;
}
.bar:nth-child(4) {
  left: 13px;
  animation-duration: 458ms;
}
.bar:nth-child(5) {
  left: 17px;
  animation-duration: 400ms;
}
.bar:nth-child(6) {
  left: 21px;
  animation-duration: 427ms;
}
.bar:nth-child(7) {
  left: 25px;
  animation-duration: 441ms;
}
.bar:nth-child(8) {
  left: 29px;
  animation-duration: 419ms;
}
.bar:nth-child(9) {
  left: 33px;
  animation-duration: 487ms;
}
.bar:nth-child(10) {
  left: 37px;
  animation-duration: 442ms;
}
.bar:nth-child(11) {
  left: 42px;
  animation-duration: 474ms;
}
.bar:nth-child(12) {
  left: 47px;
  animation-duration: 433ms;
}
.bar:nth-child(13) {
  left: 53px;
  animation-duration: 407ms;
}
.bar:nth-child(14) {
  left: 58px;
  animation-duration: 458ms;
}
.bar:nth-child(15) {
  left: 63px;
  animation-duration: 400ms;
}
.bar:nth-child(16) {
  left: 68px;
  animation-duration: 427ms;
}
.bar:nth-child(17) {
  left: 73px;
  animation-duration: 441ms;
}
.bar:nth-child(18) {
  left: 78px;
  animation-duration: 419ms;
}
.bar:nth-child(19) {
  left: 83px;
  animation-duration: 487ms;
}
.bar:nth-child(20) {
  left: 87px;
  animation-duration: 442ms;
}
