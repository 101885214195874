code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --brand-color-1: #1366b0;
  --header-height: 60px;
  --roboto: "Poppins", sans-serif;
}

.roboto-light {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
}

.roboto-normal {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.roboto-bold {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif!important;
}

.main-button {
  border: 1px solid var(--brand-color-1);
  background-color: var(--brand-color-1);
  color: #fff;
  font-family: var(--roboto);
  text-transform: capitalize;
  padding: 5px 15px;
  border-radius: 8px;
  box-shadow: none;
  transition: all 500ms ease;
}

.main-button:hover {
  opacity: 0.7;
}

.alternate-button {
  border: 1px solid var(--brand-color-1);
  background-color: #fff;
  color: var(--brand-color-1);
  font-family: var(--roboto);
  text-transform: capitalize;
  padding: 5px 15px;
  border-radius: 8px;
  box-shadow: none;
  transition: all 500ms ease;
}

.disabled-button {
  border: 1px solid grey;
  background-color: grey;
  color:#000;
  font-family: var(--roboto);
  text-transform: capitalize;
  padding: 5px 15px;
  border-radius: 8px;
  box-shadow: none;
  transition: all 500ms ease;
}


.alternate-button:hover {
  opacity: 0.7;
  background-color: var(--brand-color-1);
  color: #fff;
}

.section-header {
  font-size: 14px;
  line-height: 30px;
  font-family: var(--roboto);
  color: #000;
  font-weight: bold;
  text-transform: uppercase;
}

.secion-subheader {
  font-size: 40px;
  line-height: 30px;
  color: var(--brand-color-1);
  font-weight: bold;
}

.status-progress {
  height: 10px;
  width: 200px;
  border: 1px solid var(--brand-color-1);
  border-radius: 10px;
  margin-top: 10px;
}

.status-progress-inner {
  height: 100%;
  background-color: var(--brand-color-1);
  transition: all 500ms ease;
  border-radius: 10px;
}

.bottom-buttons {
  display: flex;
  justify-content: flex-end;
}

.bottom-buttons .alternate-button {
  margin-right: 20px;
}

.audio-box {
  height: 250px;
  width: 400px;
  margin: auto;
  margin-bottom: 30px;
  border: 1px solid var(--brand-color-1);
  padding: 15px;
  border-radius: 20px;
  border-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: var(--roboto);
}

.status-time {
  font-size: 50px;
  font-weight: 600;
  color: grey;
}

.completed-mark {
  font-size: 28px;
  color: green;
}

.status-heading {
  font-weight: 500;
  margin-top: 5px;
  font-size: 18px;
  text-transform: capitalize;
}

.status-text {
  font-weight: 700;
  padding-left: 3px;
}

.status-heading {
  margin-bottom: 10px;
}

.status-text {
  margin-bottom: 20px;
}

.private-section-header {
  font-size: 17px;
  line-height: 30px;
  font-family: var(--roboto);
  color: var(--brand-color-1);
  font-weight: 600;
  text-transform: uppercase;
}

.myScrollbar::-webkit-scrollbar {
  width: 3px;
}

.myScrollbar::-webkit-scrollbar-track {
  background: rgb(179, 177, 177);
  border-radius: 10px;
  width: 5px;
}

.myScrollbar::-webkit-scrollbar-thumb {
  background: rgb(136, 136, 136);
  border-radius: 10px;
  width: 5px;
}

.myScrollbar::-webkit-scrollbar-thumb:hover {
  background: rgb(100, 100, 100);
  border-radius: 10px;
  width: 5px;
}

.myScrollbar::-webkit-scrollbar-thumb:active {
  background: rgb(68, 68, 68);
  border-radius: 10px;
  width: 5px;
}

.practice-header {
  margin-bottom: 30px;
  font-family: var(--roboto);
  font-weight: 500;
  font-size: 14px;
}

.practice-desc {
  color: grey;
  font-family: var(--roboto);
  font-size: 15px;
}

.practice-write-area {
  border: 1px solid var(--brand-color-1);
  padding: 10px 20px;
  height: 120px;
  width: 100%;
  border-radius: 20px;
  font-size: 14px;
}

.practice-write-area:focus-visible {
  outline: transparent;
  border: 1px solid var(--brand-color-1);
}

.practice-page-footer {
  display: flex;
  justify-content: space-between;
}