.landing-success-container {
  .success-left {
  }
  .success-right {
    .success-right-heading {
    }
    .success-right-detail {
      .sucess-right-author-box {
        display: flex;
        .author-image {
          img {
          }
        }
        .author-info {
          .author-name {
          }
          .author-role {
          }
        }
      }
    }
  }
}
