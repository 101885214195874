.landing-about-container {
  background-image: url("https://images.unsplash.com/photo-1600195077909-46e573870d99?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  position: relative;
  z-index: 1;
  color: #fff;
  text-align: center;
  padding: 80px 0px;
  &::after {
    content: "";
    background-color: #11538c;
    opacity: 0.9;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -2;
  }
  .landing-about-header {
  }
  .landing-about-detail {
  }
  .landing-about-button{

  }
}
