.my-layouty-body {
  display: flex;
  flex-direction: column;
  margin-left: 200px;
  background-color: #141b21;
  transition: all 202ms ease-in-out;
  padding: 20px;
  padding-top: 90px;
  position: relative;
  z-index: 2;
  display: flex;
  position: fixed;
  font-family: var(--roboto) !important;
  width: calc(100vw - 200px);
  @media (max-width: 992px) {
    width: 100%;
    margin-left: 0;
  }
  @media (max-width: 768px) {
    padding: 10px;
    padding-top: 90px;
  }
}
.my-layout-collapsed {
  margin-left: 100px;
  width: calc(100vw - 100px);
}
.my-layout-collapsed-tablet {
  margin-left: 0px;
  width: calc(100vw - 0px);
}
.my-layout-right {
  width: 20%;
}
.my-right-collapsed {
}
.np-admin-main-button {
  border: 2px var(--main) solid;
  background-color: transparent;
  padding: 3px 12px;
  border-radius: 20px;
  text-transform: capitalize;
  cursor: pointer;
  color: #fff;
}
.add-button {
  margin-bottom: 10px;
}

.image-click-display-container {
  display: flex;
  .image-display-on-click {
    height: 100px;
    width: 100px;
    display: flex;
  }
  .image-click-box {
    height: 100px;
    width: 100px;
    border: 1px solid #fff;
    font-size: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-right: 20px;
    color: #fff;
  }
}
.mobile-collapsed-drop {
  position: fixed;
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;
  z-index: 3;
  display: none;
  background-color: rgba(0, 0, 0, 0.5);
  @media (max-width: 992px) {
    display: block;
  }
}
.drop-input {
  height: 40px;
  border: 1px solid #fff;
  border-radius: 30px;
  outline: none;
  padding: 20px 20px;
  width: 100%;
  font-family: var(--roboto) !important;
}
.table-container {
  margin-top: 10px;
  margin-bottom: 15px;
}
.table-heading {
  color: red;
}
.table-inner-heading {
  color: white;
  margin-top: 15px;
}
.switch-header{
  color: #fff;
  font-size: 16px;
}