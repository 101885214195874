.sider-logo {
  height: 75px;
  background-color: #141b21;
  color: #fff;
  display: flex;
  justify-content: center;
  padding-top: 10px;
}
.sider-logo img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.sider-container {
  height: 100vh;
  position: fixed !important;
  width: 200px !important;
  left: 0;
  top: 0;
  z-index: 5;
}

.slider-icon {
  color: #fff;
  margin-right: 5px;
}
.sider-menu {
  height: calc(100vh - 60px);
  background-color: #141b21!important;
  overflow-y: scroll;


}
.sider-menu a{
  text-decoration: none;
}
/*********menu item li changes on hover and selected ************/

.sider-menu .ant-menu-item-selected {
  color: var(--main) !important;
  background-color: transparent !important;
}
.sider-menu .ant-menu-item-active {
  color: var(--main) !important;
  background-color: transparent !important;
}

/*********menu item submenu title changes on hover and selected ************/

.sider-menu .ant-menu-submenu-selected .ant-menu-title-content {
  color: var(--main) !important;
}

.sider-menu .ant-menu-submenu-active .ant-menu-title-content {
  color: var(--main) !important;
}

/*********menu item submenu icon changes on hover and selected ************/

.sider-menu .ant-menu-submenu-selected .ant-menu-submenu-title .ant-menu-item-icon {
  color: var(--main) !important;
}

.sider-menu .ant-menu-submenu-active .ant-menu-submenu-title .ant-menu-item-icon {
  color: var(--main) !important;
}

/*********menu item title changes on hover and selected ************/

.sider-menu .ant-menu-item-selected .ant-menu-title-content a {
  color: var(--main) !important;
  background-color: transparent !important;
}
.sider-menu .ant-menu-item-active .ant-menu-title-content a {
  color: var(--main) !important;
  background-color: transparent !important;
}

/*********menu item icon changes on hover and selected ************/
.sider-menu .ant-menu-item-active .ant-menu-item-icon {
  color: var(--main) !important;
}
.sider-menu .ant-menu-item-selected .ant-menu-item-icon {
  color: var(--main) !important;
}
